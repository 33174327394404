import { useAuth } from 'context/AuthContext';

export function useUserAddresses() {
  const { userDoc } = useAuth();
  const { addresses } = userDoc ?? {};
  if (!addresses || !addresses.length) return [];
  // if address is shop location, move to top of list
  const sorted = addresses.filter((a) => !a.is_shop_location);
  const shopLocationAddress = addresses.find((a) => a.is_shop_location);
  !!shopLocationAddress && sorted.unshift(shopLocationAddress);
  return sorted;
}
